var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "search_member-popup",
      attrs: { title: "Search Team & Invite Member" },
      on: { cancel: _vm.close },
      model: {
        value: _vm.isVisible,
        callback: function ($$v) {
          _vm.isVisible = $$v
        },
        expression: "isVisible",
      },
    },
    [
      _c(
        "a-row",
        { attrs: { type: "flex", gutter: 1 } },
        [
          _c(
            "a-col",
            { attrs: { xs: 18 } },
            [
              _c("a-input", {
                attrs: { type: "text", placeholder: "Enter team name" },
                model: {
                  value: _vm.keyword,
                  callback: function ($$v) {
                    _vm.keyword = $$v
                  },
                  expression: "keyword",
                },
              }),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 6 } },
            [
              _c(
                "a-button",
                {
                  attrs: { block: "", type: "primary" },
                  on: { click: _vm.searchEmails },
                },
                [_vm._v("Search")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        _vm._l(_vm.teams, function (team) {
          return _c(
            "a-row",
            { key: team.id, attrs: { type: "flex", gutter: 4 } },
            [
              _c("a-col", { attrs: { span: 16 } }, [
                _vm._v(" Team name: " + _vm._s(team.team_name) + " "),
              ]),
              _c(
                "a-col",
                { staticClass: "gx-text-right", attrs: { span: 8 } },
                [
                  !team.members.length
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            block: "",
                            size: "small",
                            loading: _vm.loader && _vm.selectId === team.id,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.updateTeamMember(
                                _vm.selectMemberId,
                                "invite",
                                team.id
                              )
                            },
                          },
                        },
                        [_vm._v("\n          Add to team\n        ")]
                      )
                    : _vm._e(),
                  team.members.length &&
                  team.members[0].pivot.status === "accept"
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            block: "",
                            size: "small",
                            loading: _vm.loader && _vm.selectId === team.id,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.updateTeamMember(
                                _vm.selectMemberId,
                                "archive",
                                team.id
                              )
                            },
                          },
                        },
                        [_vm._v("\n          Remove from team\n        ")]
                      )
                    : _vm._e(),
                  team.members.length &&
                  team.members[0].pivot.status === "invite"
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "danger",
                            block: "",
                            size: "small",
                            loading: _vm.loader && _vm.selectId === team.id,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.updateTeamMember(
                                _vm.selectMemberId,
                                "archive",
                                team.id
                              )
                            },
                          },
                        },
                        [_vm._v("\n          Cancel Invitation\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
      _vm.teams.length < 1 && _vm.error_msg
        ? _c("div", { staticClass: "gx-text-center" }, [
            _vm._v("\n    Team not found\n  "),
          ])
        : _vm._e(),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }