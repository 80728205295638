var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "add-member",
      attrs: { title: "Add Member" },
      on: { cancel: _vm.close },
      model: {
        value: _vm.isVisible,
        callback: function ($$v) {
          _vm.isVisible = $$v
        },
        expression: "isVisible",
      },
    },
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { lg: 24, md: 24, sm: 24, xs: 24 } },
            [
              _c(
                "a-form",
                { attrs: { form: _vm.form }, on: { submit: _vm.addMember } },
                [
                  _c(
                    "a-form-item",
                    [
                      _c(
                        "a-row",
                        {
                          staticStyle: { "margin-left": "5px" },
                          attrs: { type: "flex", align: "middle", gutter: 1 },
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { xs: 2 } },
                            [_c("a-icon", { attrs: { type: "mail" } })],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { xs: 22 } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "work_email",
                                      {
                                        rules: [
                                          {
                                            required: true,
                                            message: "Email is required.",
                                          },
                                          {
                                            type: "email",
                                            message: "Email is invalid.",
                                          },
                                        ],
                                      },
                                    ],
                                    expression:
                                      "[\n                  'work_email',\n                  {\n                    rules: [\n                      { required: true, message: 'Email is required.' },\n                      { type: 'email', message: 'Email is invalid.' },\n                    ],\n                  },\n                ]",
                                  },
                                ],
                                attrs: {
                                  type: "email",
                                  placeholder: "Enter email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    [
                      _c(
                        "a-row",
                        {
                          staticStyle: { "margin-left": "5px" },
                          attrs: { type: "flex", align: "middle", gutter: 1 },
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { xs: 2 } },
                            [_c("a-icon", { attrs: { type: "user-add" } })],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { xs: 22 } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "first_name",
                                      {
                                        rules: [
                                          {
                                            required: true,
                                            message: "First name is required.",
                                          },
                                          {
                                            min: 3,
                                            message:
                                              "First name should contain at least 3 Characters.",
                                          },
                                          {
                                            max: 20,
                                            message:
                                              "Sorry You are Exceeding the Limit.",
                                          },
                                        ],
                                      },
                                    ],
                                    expression:
                                      "[\n                  'first_name',\n                  {\n                    rules: [\n                      { required: true, message: 'First name is required.' },\n                      {\n                        min: 3,\n                        message: 'First name should contain at least 3 Characters.',\n                      },\n                      {\n                        max: 20,\n                        message: 'Sorry You are Exceeding the Limit.',\n                      },\n                    ],\n                  },\n                ]",
                                  },
                                ],
                                attrs: {
                                  type: "text",
                                  placeholder: "Enter first name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    [
                      _c(
                        "a-row",
                        {
                          staticStyle: { "margin-left": "5px" },
                          attrs: { type: "flex", align: "middle", gutter: 1 },
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { xs: 2 } },
                            [_c("a-icon", { attrs: { type: "user-add" } })],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { xs: 22 } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "last_name",
                                      {
                                        rules: [
                                          {
                                            required: true,
                                            message: "Last name is required.",
                                          },
                                          {
                                            min: 3,
                                            message:
                                              "Last name should contain at least 3 Characters.",
                                          },
                                          {
                                            max: 20,
                                            message:
                                              "Sorry You are Exceeding the Limit.",
                                          },
                                        ],
                                      },
                                    ],
                                    expression:
                                      "[\n                  'last_name',\n                  {\n                    rules: [\n                      { required: true, message: 'Last name is required.' },\n                      {\n                        min: 3,\n                        message: 'Last name should contain at least 3 Characters.',\n                      },\n                      {\n                        max: 20,\n                        message: 'Sorry You are Exceeding the Limit.',\n                      },\n                    ],\n                  },\n                ]",
                                  },
                                ],
                                attrs: {
                                  type: "text",
                                  placeholder: "Enter Last name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    [
                      _c(
                        "a-row",
                        {
                          staticStyle: { "margin-left": "5px" },
                          attrs: { type: "flex", align: "middle", gutter: 1 },
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { xs: 2 } },
                            [_c("a-icon", { attrs: { type: "mobile" } })],
                            1
                          ),
                          _c("a-col", { attrs: { xs: 22 } }, [
                            _c(
                              "div",
                              { staticClass: "custom-phone" },
                              [
                                _c("vue-phone-number-input", {
                                  attrs: {
                                    color: "#d9d9d9",
                                    "valid-color": "#d9d9d9",
                                    "error-color": "#d9d9d9",
                                    "default-country-code": _vm.phoneIso,
                                    "preferred-countries":
                                      _vm.preferredCountries,
                                    "all-letters-characters": true,
                                  },
                                  on: { update: _vm.onCountrySelect },
                                  model: {
                                    value: _vm.phone,
                                    callback: function ($$v) {
                                      _vm.phone = $$v
                                    },
                                    expression: "phone",
                                  },
                                }),
                              ],
                              1
                            ),
                            !_vm.validPhone
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "has-error",
                                    attrs: { id: "number_fld" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ant-form-explain text-left",
                                      },
                                      [_vm._v(_vm._s(_vm.invalidPhoneMsg))]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    [
                      _c(
                        "a-row",
                        {
                          staticStyle: { "margin-left": "5px" },
                          attrs: { type: "flex", align: "middle", gutter: 1 },
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { xs: 2 } },
                            [_c("a-icon", { attrs: { type: "environment" } })],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { xs: 22 } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "address_1",
                                      {
                                        rules: [
                                          {
                                            required: true,
                                            message: "Address is required.",
                                          },
                                        ],
                                      },
                                    ],
                                    expression:
                                      "[\n                  'address_1',\n                  {\n                    rules: [{ required: true, message: 'Address is required.' }],\n                  },\n                ]",
                                  },
                                ],
                                attrs: {
                                  type: "text",
                                  placeholder: "Enter address",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    [
                      _c(
                        "a-row",
                        {
                          staticStyle: { "margin-left": "5px" },
                          attrs: { type: "flex", align: "middle", gutter: 1 },
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { xs: 2 } },
                            [_c("a-icon", { attrs: { type: "user" } })],
                            1
                          ),
                          _c("a-col", { attrs: { xs: 22 } }, [
                            _c(
                              "div",
                              { staticClass: "custom-phone" },
                              [
                                _c("vue-phone-number-input", {
                                  attrs: {
                                    color: "#d9d9d9",
                                    "valid-color": "#d9d9d9",
                                    "error-color": "#d9d9d9",
                                    "default-country-code":
                                      _vm.emergencyPhoneIso,
                                    "preferred-countries":
                                      _vm.preferredCountries,
                                    "all-letters-characters": true,
                                  },
                                  on: {
                                    update: _vm.onEmergencyPhoneCountrySelect,
                                  },
                                  model: {
                                    value: _vm.emergency_phone,
                                    callback: function ($$v) {
                                      _vm.emergency_phone = $$v
                                    },
                                    expression: "emergency_phone",
                                  },
                                }),
                              ],
                              1
                            ),
                            !_vm.validEmergencyPhone
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "has-error",
                                    attrs: { id: "sndnumber_fld" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ant-form-explain text-left",
                                      },
                                      [
                                        _vm._v(
                                          "\n                  Invalid emergency phone no.\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    [
                      _c(
                        "a-row",
                        {
                          staticStyle: { "margin-left": "5px" },
                          attrs: { type: "flex", align: "middle", gutter: 1 },
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { xs: 2 } },
                            [_c("a-icon", { attrs: { type: "calendar" } })],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { xs: 22 } },
                            [
                              _c("a-date-picker", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: ["dob"],
                                    expression: "['dob']",
                                  },
                                ],
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "disabled-date": _vm.disabledDate,
                                  format: "DD/MM/YYYY",
                                  placeholder: "Date of birth",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    [
                      _c(
                        "a-row",
                        {
                          staticClass: "gx-text-right",
                          staticStyle: { "margin-top": "0px" },
                          attrs: { type: "block", gutter: 1 },
                        },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                "inline-block": "",
                                type: "primary",
                                "html-type": "submit",
                                loading: _vm.memberLoading,
                              },
                            },
                            [_vm._v("Add Member")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }